import adjustViewport from './function/adjustViewport';
import SmoothScroll from './module/SmoothScroll';
import ScrollAnimation from './module/ScrollAnimation';

// ハンバーガー
const ham = document.querySelector('#js-hamburger'); 
const nav = document.querySelector('#js-drawer'); 
const link = document.querySelector('.p-drawer__link');
ham.addEventListener('click', function () { 
  ham.classList.toggle('is-active'); 
  nav.classList.toggle('is-active'); 
});
//ページ内リンクでもメニューとじる
link.addEventListener('click', function () { 
  ham.classList.toggle('is-active'); 
  nav.classList.toggle('is-active'); 
});

// ヘッダー色変える
const fixedHeaderObserve = () => {
  const headerElement = document.querySelector("#js-header");
  const targetElement = document.querySelector(".js-header-observer");

  if (!headerElement || !targetElement) return;

  const observeHandler = (entries) => {
    headerElement.setAttribute(
      "data-active",
      String(!entries[0].isIntersecting)
    );
  };

  const headerObserver = new window.IntersectionObserver(observeHandler);
  headerObserver.observe(targetElement);
};

document.addEventListener("DOMContentLoaded", fixedHeaderObserve, false);



// 制作実績スワイパー
const mySwiper = new Swiper('.swiper', {
  // loop: true,

  breakpoints: {
    375: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    758: {
      slidesPerView: 2.4,
      spaceBetween: 40,
    }
  },
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar", // プログレスバー
    // clickable: true, 無効化
  },
});

// ブレイクポイント
const bp = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};

const init = () => {

  (() => {
    const smoothScroll = new SmoothScroll({
      targets: 'a[href^="#"]',
      fixHeader: '#js-header',
    });
    smoothScroll.init();
  })();

  (() => {
    const scrollAnimation = new ScrollAnimation();
    scrollAnimation.init();
  })();

};

window.addEventListener('DOMContentLoaded', () => {
  adjustViewport();
  init();
});



